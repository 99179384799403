import React, { useState, useEffect } from "react";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import { useSwipeable, Swipeable } from "react-swipeable";
import useInterval from "../../react/useInterval";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMedia } from "use-media";
import {MobNextArrowIcon} from "../common/Icons"



TabSlider.MainHeader = ({ children }) => {
  return <>{children}</>;
};

TabSlider.Content = ({ children }) => {
  return <>{children}</>;
};
TabSliderOffice.MainHeader = ({ children }) => {
  return <>{children}</>;
};

TabSliderOffice.Content = ({ children }) => {
  return <>{children}</>;
};
TabSliderMdm.MainHeader = ({ children }) => {
  return <>{children}</>;
};

TabSliderMdm.Content = ({ children }) => {
  return <>{children}</>;
};


const ButtonList = ({ tabFor, image, title }) => {
  return (
    <>
      <TabList>
        <Tab tabFor={tabFor}>{title}</Tab>
      </TabList>

      <TabPanel tabId={tabFor}>
        <img src={image} alt={title}></img>
      </TabPanel>
    </>
  );
};

export const access_management = require("../../assets/images/landing_page/access_management.png");
export const asset_requests = require("../../assets/images/landing_page/asset_requests.png");
export const offboarding = require("../../assets/images/landing_page/offboarding.png");
export const onboarding = require("../../assets/images/landing_page/onboarding.png");
export const password_resets = require("../../assets/images/landing_page/password_resets.png");
export const unlock_account = require("../../assets/images/landing_page/unlock_account.png");
export const user_provisioning = require("../../assets/images/landing_page/user_provisioning.png");
export const create_group = require("../../assets/images/landing_page/create_group.png");
export const unlock_device = require("../../assets/images/landing_page/unlock_device.png"); 
export const reimbursement = require("../../assets/images/landing_page/reimbursement.png"); 
export const remote_wipe = require("../../assets/images/landing_page/remote_wipe.png"); 


export const expense = require("../../assets/images/landing_page/expense.png");
export const policy = require("../../assets/images/landing_page/policy.png");
export const leave = require("../../assets/images/landing_page/leave.png");
export const salary = require("../../assets/images/landing_page/salary.png");
export const pto = require("../../assets/images/landing_page/pto.png");

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "unlock account",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "password resets",
  },
  {
    tabFor1: "vertical-tab-three",
    tabName: "user provisioning",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "access management",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "asset requests",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: " onboarding",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "offboarding",
  },
];

export function TabSlider({
  children,
  content,
  image,
  slideMenu,
  slides,
  buttonMenu,
  headContent,
  middleContent,
  tailContent,
  bottomContent,
  additionalClass,
}) {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const isSmall = useMedia({ maxWidth: "520px" });


  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <section
        className={`features_img_left pl-10-tage landing_page trial_page_img landing_page_chatbot background-color-update-chatbot ${
          additionalClass ? additionalClass : ""
        }`}
        id="workflow"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 feature_page_content_left">
              {children}
              <p className="desktop_tab_list">
                {headContent}  {" "}
                {buttonMenu.slice(0, 5).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab tabFor={menu.tabFor1 && menu.tabFor1}>
                            {menu.tabName}
                          </Tab>
                        ) : null}
                      </span>
                      {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                    </li>
                  </>
                ))}
                {middleContent && (
                  <>
                  {middleContent}{" "}
                  </>
                )}
                 {/* {middleContent}{" "} */}
                {buttonMenu.slice(5, 7).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i + 5);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab className="test" tabFor={menu.tabFor1}>
                            {menu.tabName}
                          </Tab>
                        ) : null}
                      </span>
                      {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                    </li>
                   
                  </>
                ))}
                {" "}{tailContent}
              </p>
              <p className="mobile_tab_list">
                {headContent} {" "}
                {buttonMenu.slice(0, 5).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -400}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                      </AnchorLink>
                    </li>
                  </>
                ))}
                {middleContent && (
                  <>
                  {middleContent}{" "}
                  </>
                )}
                {buttonMenu.slice(5, 7).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -400}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i + 5);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                      </AnchorLink>
                    </li>
                   
                  </>
                ))}{" "}
                {tailContent}
              </p>
              <p className="second_p">{bottomContent}</p>
            </div>
            <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
              <div className="desktop_tabs">
                <TabList>
                  {slides.map((menu) => (
                    <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                  ))}
                </TabList>
                {slides.map((menu) => (
                  <TabPanel tabId={menu.tabFor}>
                    <img src={menu.image} alt={menu.alt}></img>
                  </TabPanel>
                ))}
              </div>
              <div className="slider_wrapper">
                <div
                  className="slider_container"
                  id={slides[offset].tabFor}
                  {...handlers}
                >
                  <img src={slides[offset].image} alt={slides[offset].alt} />
                </div>
                <div className="controls_wrapper">
                  <span
                    className="controls_left"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      decrementOffset();
                    }}
                  >
                    {isSmall ? <MobNextArrowIcon /> :
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="left_arrow" transform="translate(-20 -1622)">
                        <circle
                          id="Ellipse_254"
                          data-name="Ellipse 254"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(20 1622)"
                          fill="#fff"
                        />
                        <g
                          id="Group_6414"
                          data-name="Group 6414"
                          transform="translate(-396 -258)"
                        >
                          <g
                            id="Group_6413"
                            data-name="Group 6413"
                            transform="translate(424 1886)"
                          >
                            <path
                              id="Path_3332"
                              data-name="Path 3332"
                              d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                              transform="translate(-424 -1886)"
                              fill="#2c7357"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                }
                  </span>
                  <span>{slides[offset].title}</span>
                  <span
                    className="controls_right"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      incrementOffset();
                    }}
                  >
                     {isSmall ? <MobNextArrowIcon /> :
                    <svg
                      id="right_arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        id="Ellipse_254"
                        data-name="Ellipse 254"
                        cx="12"
                        cy="12"
                        r="12"
                        fill="#fff"
                      />
                      <g
                        id="Group_6414"
                        data-name="Group 6414"
                        transform="translate(8.861 6)"
                      >
                        <g id="Group_6413" data-name="Group 6413">
                          <path
                            id="Path_3332"
                            data-name="Path 3332"
                            d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                            transform="translate(-424 -1886)"
                            fill="#2c7357"
                          />
                        </g>
                      </g>
                    </svg>
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabProvider>
  );
}
export function TabSliderOffice({
  children,
  content,
  image,
  slideMenu,
  slides,
  buttonMenu,
  headContent,
  middleContent,
  tailContent,
  bottomContent,
}) {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const isSmall = useMedia({ maxWidth: "520px" });


  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <section
        className="features_img_left pl-10-tage landing_page trial_page_img landing_page_chatbot"
        id="workflow"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 feature_page_content_left">
              {children}
              <p className="desktop_tab_list">
                {headContent}
                {buttonMenu.slice(0, 4).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab tabFor={menu.tabFor1 && menu.tabFor1}>
                            {menu.tabName}
                          </Tab>
                        ) : null}
                      </span>
                      {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                    </li>
                  </>
                ))}
                {middleContent && (
                  <>
                  {middleContent}{" "}
                  </>
                )}
                 {/* {middleContent}{" "} */}
                {buttonMenu.slice(4, 7).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i + 4);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab className="test" tabFor={menu.tabFor1}>
                            {menu.tabName}
                          </Tab>
                        ) : null}
                      </span>
                      {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                    </li>
                   
                  </>
                ))}
                {" "}{tailContent}
              </p>
              <p className="mobile_tab_list">
                {headContent}
                {buttonMenu.slice(0, 4).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -400}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                      </AnchorLink>
                    </li>
                  </>
                ))}
                {middleContent && (
                  <>
                  {middleContent}{" "}
                  </>
                )}
                {buttonMenu.slice(4, 7).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -400}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i + 4);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                      </AnchorLink>
                    </li>
                   
                  </>
                ))}{" "}
                {tailContent}
              </p>
              <p className="second_p">{bottomContent}</p>
            </div>
            <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
              <div className="desktop_tabs">
                <TabList>
                  {slides.map((menu) => (
                    <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                  ))}
                </TabList>
                {slides.map((menu) => (
                  <TabPanel tabId={menu.tabFor}>
                    <img src={menu.image} alt={menu.alt}></img>
                  </TabPanel>
                ))}
              </div>
              <div className="slider_wrapper">
                <div
                  className="slider_container"
                  id={slides[offset].tabFor}
                  {...handlers}
                >
                  <img src={slides[offset].image} alt={slides[offset].alt} />
                </div>
                <div className="controls_wrapper">
                  <span
                    className="controls_left"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      decrementOffset();
                    }}
                  >
                     {isSmall ? <MobNextArrowIcon /> :
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="left_arrow" transform="translate(-20 -1622)">
                        <circle
                          id="Ellipse_254"
                          data-name="Ellipse 254"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(20 1622)"
                          fill="#fff"
                        />
                        <g
                          id="Group_6414"
                          data-name="Group 6414"
                          transform="translate(-396 -258)"
                        >
                          <g
                            id="Group_6413"
                            data-name="Group 6413"
                            transform="translate(424 1886)"
                          >
                            <path
                              id="Path_3332"
                              data-name="Path 3332"
                              d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                              transform="translate(-424 -1886)"
                              fill="#2c7357"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                    }
                  </span>
                  <span>{slides[offset].title}</span>
                  <span
                    className="controls_right"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      incrementOffset();
                    }}
                  >
                     {isSmall ? <MobNextArrowIcon /> :
                    <svg
                      id="right_arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        id="Ellipse_254"
                        data-name="Ellipse 254"
                        cx="12"
                        cy="12"
                        r="12"
                        fill="#fff"
                      />
                      <g
                        id="Group_6414"
                        data-name="Group 6414"
                        transform="translate(8.861 6)"
                      >
                        <g id="Group_6413" data-name="Group 6413">
                          <path
                            id="Path_3332"
                            data-name="Path 3332"
                            d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                            transform="translate(-424 -1886)"
                            fill="#2c7357"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabProvider>
  );
}
export function TabSliderMdm({
  children,
  content,
  image,
  slideMenu,
  slides,
  buttonMenu,
  headContent,
  middleContent,
  tailContent,
  bottomContent,
  additionalClass,
}) {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const isSmall = useMedia({ maxWidth: "520px" });


  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <section
        className={`features_img_left pl-10-tage landing_page trial_page_img landing_page_chatbot background-color-update-chatbot ${
          additionalClass ? additionalClass : ""
        }`}
        id="workflow"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 feature_page_content_left">
              {children}
              <p className="desktop_tab_list">
                {headContent}
                {buttonMenu.slice(0, 6).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab tabFor={menu.tabFor1 && menu.tabFor1}>
                            {menu.tabName}
                          </Tab>
                        ) : null}
                      </span>
                      {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                    </li>
                  </>
                ))}
                {middleContent && (
                  <>
                  {middleContent}{" "}
                  </>
                )}
                 {/* {middleContent}{" "} */}
                {buttonMenu.slice(6, 8).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i + 6);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab className="test" tabFor={menu.tabFor1}>
                            {menu.tabName}
                          </Tab>
                        ) : null}
                      </span>
                      {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                    </li>
                   
                  </>
                ))}
                {" "}{tailContent}
              </p>
              <p className="mobile_tab_list">
                {headContent}
                {buttonMenu.slice(0, 6).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -400}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                      </AnchorLink>
                    </li>
                  </>
                ))}
                {middleContent && (
                  <>
                  {middleContent}{" "}
                  </>
                )}
                {buttonMenu.slice(6, 8).map((menu, i) => (
                  <>
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -400}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i + 6);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        {menu.type == "lastBefore"
                        ? "/"
                        : menu.type == "lastAfter"
                        ? ""
                        : <span>,&nbsp;</span>}
                      </AnchorLink>
                    </li>
                   
                  </>
                ))}{" "}
                {tailContent}
              </p>
              <p className="second_p">{bottomContent}</p>
            </div>
            <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
              <div className="desktop_tabs">
                <TabList>
                  {slides.map((menu) => (
                    <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                  ))}
                </TabList>
                {slides.map((menu) => (
                  <TabPanel tabId={menu.tabFor}>
                    <img src={menu.image} alt={menu.alt}></img>
                  </TabPanel>
                ))}
              </div>
              <div className="slider_wrapper">
                <div
                  className="slider_container"
                  id={slides[offset].tabFor}
                  {...handlers}
                >
                  <img src={slides[offset].image} alt={slides[offset].alt} />
                </div>
                <div className="controls_wrapper">
                  <span
                    className="controls_left"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      decrementOffset();
                    }}
                  >
                     {isSmall ? <MobNextArrowIcon /> :
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g id="left_arrow" transform="translate(-20 -1622)">
                        <circle
                          id="Ellipse_254"
                          data-name="Ellipse 254"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(20 1622)"
                          fill="#fff"
                        />
                        <g
                          id="Group_6414"
                          data-name="Group 6414"
                          transform="translate(-396 -258)"
                        >
                          <g
                            id="Group_6413"
                            data-name="Group 6413"
                            transform="translate(424 1886)"
                          >
                            <path
                              id="Path_3332"
                              data-name="Path 3332"
                              d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                              transform="translate(-424 -1886)"
                              fill="#2c7357"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
}
                  </span>
                  <span>{slides[offset].title}</span>
                  <span
                    className="controls_right"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      incrementOffset();
                    }}
                  >
                     {isSmall ? <MobNextArrowIcon /> :
                    <svg
                      id="right_arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        id="Ellipse_254"
                        data-name="Ellipse 254"
                        cx="12"
                        cy="12"
                        r="12"
                        fill="#fff"
                      />
                      <g
                        id="Group_6414"
                        data-name="Group 6414"
                        transform="translate(8.861 6)"
                      >
                        <g id="Group_6413" data-name="Group 6413">
                          <path
                            id="Path_3332"
                            data-name="Path 3332"
                            d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                            transform="translate(-424 -1886)"
                            fill="#2c7357"
                          />
                        </g>
                      </g>
                    </svg>
}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabProvider>
  );
}

const slidesMob = [
  {
    image: unlock_account,
    title: "Unlock Account",
    tabFor: "vertical-tab-one",
  },
  {
    image: password_resets,
    title: "Password Reset",
    tabFor: "vertical-tab-two",
  },
  {
    image: user_provisioning,
    title: "User Provisioning",
    tabFor: "vertical-tab-three",
  },
  {
    image: access_management,
    title: "Access Management",
    tabFor: "vertical-tab-four",
  },
  {
    image: asset_requests,
    title: "Asset Requests",
    tabFor: "vertical-tab-five",
  },
  {
    image: onboarding,
    title: "Onboarding",
    tabFor: "vertical-tab-six",
  },
  {
    image: offboarding,
    title: "Offboarding",
    tabFor: "vertical-tab-seven",
  },
];

