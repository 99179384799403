import React, { useEffect } from "react";
import ArrowRightICon from "../../components/common/Icons";
import { redirectToWithUtm } from "../Header";
import * as $ from "jquery";
if (typeof window !== `undefined`) {
  require("../../assets/js/slick.js");
  require("../../assets/styles/slick.css");
  require("../../assets/styles/slick-theme.css");
}

export function Slider(props) {
  useEffect(() => {
    setTimeout(
      () =>
        $(".slick-carousel").slick({
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 5000,
          arrows: true,
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 777,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }),
      0
    );
  }, []);

  const {
    sliderData,
    title,
    marketplaceTitle,
    additionalClass,
    additionalClassForSlide,
  } = props;

  return (
    <section
      className={`featured_content ${additionalClass ? additionalClass : ""}`}
    >
      <div className="container">
        <div className="drag">Drag</div>
        <h3 class="font-section-sub-header chat-channel-slider-header-bot">
          {title}
        </h3>
        <div
          className={`left-whitespace  ${
            additionalClass ? additionalClass : ""
          }`}
        />
        <div className="slick-carousel">
          {sliderData.map((slider, i) => (
            <div className="cards_slider" key={i}>
              <div className="benefits-workplace-content cogni_workspace_content card-slider-bamboo-chatbot">
                <div className="integrations_card_slider card-slider-top-bamboo-chatbot">
                  <img src={slider.image} alt={slider.alt} />
                  <h3
                    className={`text-overflow-ellipsis-bot ${
                      additionalClassForSlide ? additionalClassForSlide : ""
                    }`}
                  >
                    {slider.header}
                  </h3>
                </div>
                <p>{slider.content}</p>
                <div className="cards_dateime card-slider-bottom-bamboo-chatbot">
                  <a
                    onClick={() => {
                      redirectToWithUtm(
                        "https://assistant.workativ.com/authentication/u/direct-signup"
                      );
                    }}
                    href="#"
                    className="font-section-download-chatbot-small"
                  >
                    Download this workflow &#62;
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="card_link_landing">
          <a
            href="/conversational-ai-platform/app-workflow-template-marketplace"
            className="url_manipulation"
          >
            {marketplaceTitle ? marketplaceTitle : "Automations Marketplace"}{" "}
            <span class="arrow_svg_link">
              <svg
                aria-hidden="true"
                data-prefix="fal"
                data-icon="long-arrow-right"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}
