import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import "react-web-tabs/dist/react-web-tabs.css";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnboardingSlider } from "../conversational-ai-platform";
import { OnboardingMobile } from "../conversational-ai-platform";
import { VideoElement2 } from "../../components/video";
import { AppsIntegrations } from "../conversational-ai-platform";
import { useMedia } from "use-media";
import LeftImageWithContent from "../../components/chat-channel/WithBgLeftImage";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import { Slider } from "../../components/common/sliderCaurosal";
import ArrowRightICon from "../../components/common/Icons";
import { TabSlider } from "../../components/integration/TabSlider";
import { buttonMenu } from "./zenefits-chatbot";
import { redirectToWithUtm } from "../../components/Header";

const topImage = require("../../assets/img/integration/bamboohr/hr_header.png");

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");

const actionImg = require("../../assets/img/integration/bamboohr/action.svg");

const VidImg = require("../../assets/img/integration/bamboohr/video_img_bamboo.png");

const LogoImg = require("../../assets/images/icons-web/bamboo.svg");

const section_one = require("../../assets/img/integration/bamboohr/hr_sec_1.png");
const section_three = require("../../assets/img/integration/bamboohr/hr_sec_3.png");
const section_four = require("../../assets/img/integration/bamboohr/hr_sec_4.png");
const section_five = require("../../assets/img/chatbot/it_chatbot/it_bot_features_5.png");

const salary = require("../../assets/images/landing_page/hr_bot_1.png");
const leave = require("../../assets/images/landing_page/hr_bot_2.png");
const expense = require("../../assets/images/landing_page/hr_bot_3.png");
const policy = require("../../assets/images/landing_page/hr_bot_4.png");
const offboarding = require("../../assets/images/landing_page/hr_bot_5.png");
const onboarding = require("../../assets/images/landing_page/hr_bot_6.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const OnboardSlide = require("../../assets/images/onboarding/onboarding_slider_1.png");
const OnboardSlide1 = require("../../assets/img/chatbot/hr_chatbot/hr_onboarding_2.png");
const OnboardSlide2 = require("../../assets/img/chatbot/hr_chatbot/hr_onboarding_3.png");
const OnboardSlide3 = require("../../assets/images/onboarding/onboarding_slider_4.png");
const OnboardSlide4 = require("../../assets/img/chatbot/hr_chatbot/hr_onboarding_5.png");
const OnboardArrow = require("../../assets/images/onboarding/onboarding_arrow.svg");

const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const box = require("../../assets/images/icons-web/box_1.svg");
const dropbox = require("../../assets/images/icons-web/dropbox.svg");
const sharePoint = require("../../assets/images/icons-web/sharepoint.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const zoho_expense = require("../../assets/images/icons-web/zoho_expense.svg");
const expensify = require("../../assets/images/icons-web/expensify.svg");
const rydoo = require("../../assets/images/icons-web/rydoo.svg");
const trip_actions = require("../../assets/images/icons-web/trip_actions.svg");
const certify = require("../../assets/images/icons-web/certify.svg");
const twilio = require("../../assets/images/icons-web/twilio.svg");
const outlook = require("../../assets/img/integration/icons/Outlook.svg");

const featureContent = [
  {
    position: "right",
    header: "Build BambooHR self-service chatbot in minutes",
    content:
      "Use workativ’s chatbot builder to add, remove, or optimize new topics for BambooHR chatbot easily based on your requirement. Use our no-code canvas to create engaging employee experiences faster in minutes. ",
    content1:
      " Go digital with a chatbot to deliver employee support – faster, smarter, and easier.",
    image: section_one,
    alt: "How to build Zoho People chatbot",
  },

  {
    position: "tab",
    header: "BambooHR chatbot + task automation in a single platform",
    headContent:
      "Most chatbots only automate conversations. Only workativ platform provides one-click integration of BambooHR with 3rd party apps and tasks to automate repetitive HR enquires & requests such as ",
    middleContent: "",
    tailContent: "and much more.",
    bottomContent: " Help you HR teams focus on high value initiatives. ",
  },

  {
    position: "right",
    header: "Add pre-built tasks to BambooHR Chatbot from our marketplace ",
    content:
      "Workativ comes with over 600+ pre-built actions for popular apps like Access Management, Payroll, Time Management, ITSM, Document Management, Office 365, Jira and so on including BambooHR actions, that is ready to use from our app workflow marketplace. No coding. ",
    content1: "Just download, connect and go live instantly.",
    image: section_three,
    alt: "Create Zoho People tasks",
  },

  {
    position: "left",
    header:
      "Deploy BambooHR chatbot on your Slack, Teams, or a Chat Widget in few clicks",
    content:
      "Take your chatbot to where your employees are—on Slack or Teams. Give your employees a modern chatbot that resolves issues faster and easier.  ",
    content1:
      "Turn your Teams and Slack into self-service helpdesk channel for employees. ",
    link: null,
    image: section_four,
    toDisplaySlackTeamsIcons: true,
    alt: "Deploy Zoho People chatbot in Teams and Slack",
  },

  {
    position: "right",
    header: "Live Agent Handover",
    content:
      "Workativ offers seamless ‘agent handover’ of chatbot to live agents with complete context and conversation history so your agents can pick up right where the bot left, avoiding any user frustration – but most importantly solving end-user issues right away. ",
    image: section_five,
    link: "Learn More",
    url:
      "https://workativ.com/conversational-ai-platform/agent-handover-chatbot",
    alt: "How to build Zoho People chatbot",
  },
];

const SliderData = [
  {
    image: actionImg,
    header: "Add Employee",
    content: "Add Employeet in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Employee Details",
    content: "Get Employee Details in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Update Employee",
    content: "Update Employee in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get All Employees",
    content: "Get All Employees in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Time Off Request",
    content: "Get Time Off Request in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Add a Time Off Request",
    content: "Add a Time Off Request in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "List Time Off Policies for Employee",
    content: "List Time Off Policies for Employee in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Employee Time Offs",
    content: "Get Employee Time Offs in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Assign Time Off policies for an Employee",
    content: "Assign Time Off policies for an Employee in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Time Off Types",
    content: "Get Time Off Types in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Time Off Policies",
    content: "Get Time Off Policies in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Default withholding",
    content: "Get Default withholding in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get employee deductions",
    content: "Get employee deductions in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get benefit plans",
    content: "Get benefit plans in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Salary Structure",
    content: "Get Salary Structure in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Bonus Detailss",
    content: "Get Bonus Details in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Get Commission Details",
    content: "Get Commission Details in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
  {
    image: actionImg,
    header: "Benefit plan details",
    content: "Benefit plan details in BambooHR",
    cards_dateime: "Updated: 12 May 2020 2:20PM",
  },
];

const slides = [
  {
    image: salary,
    title: "Salary Enquires",
    tabFor: "vertical-tab-one",
    alt: "salary enquires chatbot",
  },
  {
    image: leave,
    title: "Apply leave",
    tabFor: "vertical-tab-two",
    alt: "apply leave chatbot",
  },
  {
    image: expense,
    title: "Expense Claims",
    tabFor: "vertical-tab-three",
    alt: "expense claims chatbot",
  },
  {
    image: policy,
    title: "Policy Searches",
    tabFor: "vertical-tab-four",
    alt: "policy searches chatbot",
  },
  {
    image: onboarding,
    title: "Onboarding",
    tabFor: "vertical-tab-five",
    alt: "onboarding chatbot",
  },
  {
    image: offboarding,
    title: "Offboarding",
    tabFor: "vertical-tab-six",
    alt: "offboarding chatbot",
  },
];

const integrationsApps = [
  {
    header: "19+ ITSM Apps",
    userApps: [
      {
        icon: servicenow,
        appName: "ServiceNow",
        url: "/conversational-ai-platform/servicenow-chatbot",
      },
      {
        icon: freshservice,
        appName: "Freshservice",
        url: "/conversational-ai-platform/freshservice-chatbot",
      },
      {
        icon: jira,
        appName: "Jira Service Desk",
        url: "/conversational-ai-platform/jira-service-desk-chatbot",
      },
      {
        icon: solarwinds,
        appName: "SolarWinds",
        url: "/conversational-ai-platform/solarwinds-chatbot",
      },
      {
        icon: service_desk,
        appName: "ServiceDesk Plus",
        url: "/conversational-ai-platform/servicedesk-plus-chatbot",
      },
    ],
  },
  {
    header: "15+ Access Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
        url: "/conversational-ai-platform/okta-chatbot",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
        url: "/conversational-ai-platform/active-directory-chatbot",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
        url: "/conversational-ai-platform/jumpcloud-chatbot",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
        url: "/conversational-ai-platform/office-365-chatbot",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
        url: "/conversational-ai-platform/onelogin-chatbot",
      },
    ],
  },
  {
    header: "05+ Expense Management Apps",
    userApps: [
      {
        icon: zoho_expense,
        appName: "Zoho Expense",
        url: "/conversational-ai-platform/zoho-expense-chatbot",
      },
      {
        icon: expensify,
        appName: "Expensify",
        url: "/conversational-ai-platform/expensify-chatbot",
      },
      {
        icon: rydoo,
        appName: "Rydoo",
        url: "/conversational-ai-platform/rydoo-chatbot",
      },
      {
        icon: trip_actions,
        appName: "Trip Actions",
        url: "/conversational-ai-platform/tripactions-chatbot",
      },
      {
        icon: certify,
        appName: "Certify Expense",
        url: "/conversational-ai-platform/certify-chatbot",
      },
    ],
  },
  {
    header: "Productivity",
    userApps: [
      {
        icon: box,
        appName: "Box",
        url: "/conversational-ai-platform/box-chatbot",
      },
      {
        icon: dropbox,
        appName: "Dropbox",
        url: "/conversational-ai-platform/dropbox-chatbot",
      },
      {
        icon: sharePoint,
        appName: "SharePoint",
        url: "/conversational-ai-platform/sharepoint-chatbot",
      },
      {
        icon: outlook,
        appName: "Outlook",
        url: "/conversational-ai-platform/outlook-chatbot",
      },
      {
        icon: twilio,
        appName: "Twilio",
        url: "/conversational-ai-platform/twilio-chatbot",
      },
    ],
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free HR Chatbot | Workativ Assistant Chatbot"
        description="Use HR Chatbot to automate routine enquires and processes in HR. Transform your employee experience with HR chatbot and workflow automation and free up your HR staff for better productivity."
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
      />
      <section className="it_support_chatbot landing_page_chatbot">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"hr support chatbot"}
              iD={"chatbot"}
              bannerImage={customerBanner}
              bannerImageMobile={bannerImageMobile}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.LogoImage>{LogoImg}</RegisterSection.LogoImage>
                <RegisterSection.MainHeader>
                  BambooHR chatbot with app workflows
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Automate employee self-service updates, payroll enquires,
                  employee onboarding, salary advice, apply leave, and expense
                  claims with chatbot for BambooHR.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>

            {isSmall ? (
              <OnboardingMobile
                header={onboardoingSliderData.header.mobile}
                subHeader={onboardoingSliderData.subHeader.mobile}
                sliders={onboardoingSliderData.sliders}
                additionalContainerClass="padding-top-30 float-left"
              />
            ) : (
              <OnboardingSlider
                header={onboardoingSliderData.header.desktop}
                subHeader={onboardoingSliderData.subHeader.desktop}
                sliders={onboardoingSliderData.sliders}
                additionalContainerClass="padding-top-50"
              />
            )}
            <div className="last_bg_pad_0">
              {featureContent.map((feature, index) => {
                if (feature.position == "left") {
                  return (
                    <>
                      <LeftFormRightImage
                        image={feature.image}
                        altImage={feature.alt}
                      >
                        <LeftFormRightImage.Header>
                          {feature.headerTop ? (
                            <h1>{feature.header}</h1>
                          ) : (
                            <h2>{feature.header}</h2>
                          )}
                        </LeftFormRightImage.Header>
                        <LeftFormRightImage.SubHeader>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: feature.content,
                            }}
                          />
                          {feature.content1 && (
                            <p
                              className="second_p"
                              dangerouslySetInnerHTML={{
                                __html: feature.content1,
                              }}
                            />
                          )}
                        </LeftFormRightImage.SubHeader>
                        <LeftFormRightImage.Link>
                          {feature.link && (
                            <div className="card_link_landing">
                              <a
                                href={feature.url}
                                className="url_manipulation"
                              >
                                {feature.link}
                                <span className="arrow_svg_link">
                                  <ArrowRightICon />
                                </span>
                              </a>
                            </div>
                          )}
                          {feature.toDisplaySlackTeamsIcons != undefined && (
                            <SlackTeamsChat />
                          )}
                          {feature.toDisplaySlackIcons != undefined && (
                            <SlackIcons />
                          )}
                          {feature.toDisplayTeamsIcons != undefined && (
                            <TeamsIcons />
                          )}
                          {feature.toDisplayTeamsIcons != undefined && (
                            <ChatWidgetIcons />
                          )}
                        </LeftFormRightImage.Link>
                        {feature.toForm != undefined && (
                          <RequestForm isFooterForm={false} />
                        )}{" "}
                      </LeftFormRightImage>
                    </>
                  );
                }
                if (feature.position == "right") {
                  return (
                    <LeftImageWithContent
                      image={feature.image}
                      altImage={feature.alt}
                    >
                      <LeftImageWithContent.HeaderIcon>
                        <h2>{feature.header}</h2>
                      </LeftImageWithContent.HeaderIcon>
                      <LeftImageWithContent.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                        {feature.content1 && (
                          <p
                            className="second_p"
                            dangerouslySetInnerHTML={{
                              __html: feature.content1,
                            }}
                          />
                        )}
                      </LeftImageWithContent.SubHeader>
                      <LeftImageWithContent.Link>
                        <div className="card_link_landing">
                          {feature.link && (
                            <a href={feature.url} className="url_manipulation">
                              {feature.link ? feature.link : "Learn more"}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          )}
                          {feature.toDisplaySlackTeamsIcons != undefined && (
                            <SlackTeamsChat />
                          )}
                          {feature.toDisplaySlackIcons != undefined && (
                            <SlackIcons />
                          )}
                          {feature.toDisplayTeamsIcons != undefined && (
                            <TeamsIcons />
                          )}
                          {feature.toDisplayTeamsIcons != undefined && (
                            <ChatWidgetIcons />
                          )}
                        </div>
                      </LeftImageWithContent.Link>
                    </LeftImageWithContent>
                  );
                }
                if (feature.position == "tab") {
                  return (
                    <TabSlider
                      slides={slides}
                      buttonMenu={buttonMenu}
                      headContent={feature.headContent}
                      middleContent={feature.middleContent}
                      tailContent={feature.tailContent}
                      bottomContent={feature.bottomContent}
                    >
                      <TabSlider.MainHeader>
                        <h2>{feature.header}</h2>
                      </TabSlider.MainHeader>
                    </TabSlider>
                  );
                }
              })}
            </div>

            <div class="container">
              <div className="build_needs">
                <div
                  class="col-lg-12 col-md-12 col-12 pl-0 center_features_header"
                  style="margin-bottom: 20px;"
                >
                  <h2 className="font-section-header">
                    Steps to build BambooHR Chatbot with app automation
                  </h2>
                </div>
              </div>
            </div>
            <VideoElement2
              videoURL={"https://www.youtube.com/watch?v=1hqHgGJq8YY"}
              imgURL={VidImg}
              imgMob={VidImg}
            />

            <AppsIntegrations
              additionalClass="margin-bottom-0"
              header={
                <>
                  <h3 className="font-section-header mb-2">
                    Connect HR Chatbot with 70+ apps, 600+ actions, and 1000s of
                    automations instantly. No coding.
                  </h3>
                </>
              }
              footer={
                <>
                  Also available CRM, ITSM, MDM Apps, Email Management, SMS
                  Management, Notifications, and Collaboration Apps.
                </>
              }
              apps={integrationsApps}
            />

            <div className="integrations_slider_bg">
              <Slider
                additionalClass="background-color-slider"
                title={"BambooHR Automations for Chatbot"}
                sliderData={SliderData}
                marketplaceTitle={"See all app workflow"}
                additionalClassForSlide="margin-left-slide"
              />
            </div>

            <div className="container">
              <div className="usecase_blog_container">
                <h3 class="font-section-sub-header-small-bold-v2">
                  Must Read Blogs
                </h3>
                <div className="usecase_bottom_blog">
                  <ul>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://blog.workativ.com/integrate-bamboohr-with-workativ-and-simplify-hrms/

                        "
                      >
                        Integrate BambooHR with Conversational AI and simplify
                        HRMS
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                      >
                        A Guide to Conversational AI Chatbots
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://blog.workativ.com/5-benefits-of-introducing-hr-bots-to-support-remote-workers/"
                      >
                        5 Benefits of Introducing HR Bots to Support Remote
                        Workers
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const onboardoingSliderData = {
  header: {
    desktop: (
      <h2 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h2>
    ),
    mobile: (
      <h3 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h3>
    ),
  },
  subHeader: {
    desktop: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce">
        Sign up for FREE Bot
        <a
          style="color: #1857DC;"
          href="https://assistant.workativ.com/authentication/u/direct-signup"
        >
          {" "}
          here
        </a>
      </h5>
    ),
    mobile: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce">
        Sign up for FREE Bot{" "}
        <a
          style="color: #1857DC;"
          href="https://assistant.workativ.com/authentication/u/direct-signup"
        >
          {" "}
          here
        </a>
      </h5>
    ),
  },
  sliders: [
    {
      title: "Sign up",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Sign up
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                You can create a new bot or download pre-built BambooHR bot from
                marketplace.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Sign up</h3>
            <p className="font-section-normal-text">
              Go live in minutes with our onscreen onboarding
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Download BambooHR Bot",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Download BambooHR Bot
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Bot templates are pre-built bots with conversations covering
                most common and repetitive support topics. Easily download
                BambooHR bot in one-click, add new or edit existing support
                topics in minutes.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Download BambooHR Bot
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Bot templates are pre-built bots with conversations covering most
              common and repetitive support topics. Easily download BambooHR bot
              in one-click, add new or edit existing support topics in minutes.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Connect BambooHR App Workflows",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Connect BambooHR App Workflows
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Connecting chatbot with your apps and building app workflows
                requires a lot of development efforts, time, and costs. But
                workativ provides pre-built app integrations and app workflows
                so you can connect your chatbot with your app and automate tasks
                instantly. No coding.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">
              {" "}
              Connect BambooHR App Workflows
            </h3>
            <p className="font-section-normal-text">
              Connecting chatbot with your apps and building app workflows
              requires a lot of development efforts, time, and costs. But
              workativ provides pre-built app integrations and app workflows so
              you can connect your chatbot with your app and automate tasks
              instantly. No coding.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Test your Bot",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Bot Testing
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Test your bot in real time using “Try Me”. Make adjustments, add
                or edit conversations, fine tune user interactions, and review
                your bot conversations on the go before you go-live.
              </p>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Bot Testing</h3>
            <p className="font-section-normal-text">
              Test your bot in real time using “Try Me”. Make adjustments, add
              or edit conversations, fine tune user interactions, and review
              your bot conversations on the go before you go-live.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Go Live",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Deploy on Teams, Slack, or as Chat Widget
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Turn your favorite collaboration app into modern employee
                self-service by deploying chatbot on your Teams, Slack or as
                Widget on your self-help portal in few clicks. Customize the
                end-user experience by applying your company branding, logo etc.
              </p>
              <div className="icons_container_onboarding">
                <div className="icon_text">
                  <img
                    class="slack_feature_icon_onboarding"
                    src={FeatureSlack}
                  ></img>
                  <h6 className="font-section-small-text-medium  mb-0 pt-1">
                    Slack
                  </h6>
                </div>

                <div className="icon_text">
                  <img
                    class="teams_feature_icon_onboarding"
                    src={FeatureTeams}
                  ></img>
                  <h6 className="font-section-small-text-medium mb-0 pt-1">
                    Teams
                  </h6>
                </div>
                <div className="icon_text">
                  <img
                    class="chat_feature_icon_onboarding"
                    src={FeatureChat}
                  ></img>
                  <h6 className="font-section-small-text-medium  mb-0 pt-1">
                    Chat Widget
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">
              Deploy on Teams, Slack, or as Chat Widget
            </h3>
            <p className="font-section-normal-text">
              Turn your favorite collaboration app into modern employee
              self-service by deploying chatbot on your Teams, Slack or as
              Widget on your self-help portal in few clicks. Customize the
              end-user experience by applying your company branding, logo etc.
            </p>
            <div className="icons_container_onboarding_mobile">
              <img
                class="slack_feature_icon_onboarding_mobile"
                src={FeatureSlack}
              ></img>
              <h6>Slack</h6>

              <img
                class="teams_feature_icon_onboarding_mobile"
                src={FeatureTeams}
              ></img>
              <h6>Teams</h6>

              <img
                class="chat_feature_icon_onboarding_mobile"
                src={FeatureChat}
              ></img>
              <h6>Chat Widget</h6>
            </div>
          </div>

          <div className="image_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
    },
  ],
};

const SlackTeamsChat = () => (
  <div className="chat_channels_trial">
    <div
      className="chat_channels_trial_btn font-section-normal-text-medium
 color-chat-channel"
    >
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div
      className="chat_channels_trial_btn color-chat-channel font-section-normal-text-medium
margin-right-20"
    >
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
    <div
      className="chat_channels_trial_btn font-section-normal-text-medium
 color-chat-channel"
    >
      <img
        src={require("../../assets/images/features_images/features_chat.png")}
        alt="chatwidget"
      />
      Chat Widget
    </div>
  </div>
);
const SlackIcons = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
  </div>
);
const TeamsIcons = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn margin-right-20">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
const ChatWidgetIcons = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/features_images/features_chat.png")}
        alt="teams"
      />
      Chat Widget
    </div>
  </div>
);
